import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Products', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOptions() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Selects', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Selects',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Products', {
            _size: 1,
            _p: 1,
            _sort: '',
            _where: `(id,eq,${id})`,
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Products',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkForCode(ctx, code) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Products', {
            _size: 1,
            _p: 1,
            _sort: '',
            _where: `(code,like,${code})`,
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Products',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Products', clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOption(ctx, optionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Selects', optionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Users', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Users',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteRow/Products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/updateRow/Products/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
