import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'наименование', sortable: true },
    { key: 'group', label: 'группа', sortable: false },
    { key: 'manufacturer', label: 'Производитель', sortable: true },
    { key: 'unit', label: 'Ед. изм', sortable: true },
    { key: 'init_price', label: 'Покупная цена', sortable: true },
    { key: 'last_price', label: 'Цена продажи', sortable: true },
    { key: 'discount_price', label: 'Цена со скидкой', sortable: false },
    { key: 'description', label: 'описание', sortable: true },
    { key: 'code', label: 'код', sortable: true },
    { key: 'actions' },
    // {
    //   key: 'resp_user_id',
    //   label: 'Responsible',
    //   // formatter: title,
    //   sortable: true,
    // },
  ]

  const queryFields = [
    { key: 'name', whereKeys: ['name'], sortKey: 'name' },
    { key: 'group', whereKeys: ['group'], sortKey: 'group' },
    {
      key: 'manufacturer',
      whereKeys: ['manufacturer'],
      sortKey: 'manufacturer',
    },
    { key: 'unit', whereKeys: ['unit'], sortKey: 'unit' },
    { key: 'init_price', whereKeys: ['init_price'], sortKey: 'init_price' },
    { key: 'last_price', whereKeys: ['last_price'], sortKey: 'last_price' },
    {
      key: 'discount_price',
      whereKeys: ['discount_price'],
      sortKey: 'discount_price',
    },
    { key: 'description', whereKeys: ['description'], sortKey: 'description' },
    { key: 'code', whereKeys: ['code'], sortKey: 'code' },
  ]

  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData()
    },
  )

  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })

    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < queryFields.length; i++) {
    //   const columns = queryFields[i].whereKeys
    //   columns.forEach(column => {
    //     where.push(`(${column},like,~${val}~)`)
    //   })
    // }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('app-product/fetchProducts', {
        // _join: 'cl.Products,_lj,u0.Users',
        // _on1: '(cl.resp_user_id,eq,u0.id)',
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + sortBy.value,
        _where:
          (statusFilter.value !== null
            ? `(cl.type,eq,${statusFilter.value})`
            : '') + (searchQuery.value !== ''
            ? q(searchQuery.value, statusFilter.value !== null)
            : ''),
        _fields: '-',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Products',
      })
      .then(response => {
        const { records, total } = response.data

        callback(records)
        totalProducts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const deleteProduct = id => {
    store
      .dispatch('app-product/deleteProduct', id)
      .then(response => {
        if (response.data.status === 'success') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно удалили товар.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          // purchaseData.value = response.data
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: response.data.err,
            },
          })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    deleteProduct,
  }
}
